<script>
    import {onMount, getContext} from "svelte";

    export let name;
    export let value = '';
    export let required = false;
    export let maxLength = 50;
    export let type;
    export let autoCapitalize = true;
    export let spellcheck = true;
    export let autocomplete = true;
    export let placeholder = '';
    export let inputmode;
    export let visuallyHidden = false;

    let values = getContext('values') || {};
    let validationErrors = getContext('validation-errors') || {};

    let inputElement
    $: inputType = type;

    onMount(async () => {
        if (values[name]) {
            value  = values[name];
        }

        if (visuallyHidden && validationErrors[name]) {
            visuallyHidden = false;
        }
    });

    export function focus() {
        inputElement.focus();
    }

    function updateValue(e) {
        value = e.target.value;
    }
</script>


<div>
    <span class="animated-border"></span>
    <input
            type="{inputType}"
            {name}
            id={name}
            {value}
            {required}
            {maxLength}
            autocapitalize={autoCapitalize ? null : 'off'}
            spellcheck={spellcheck ? null : 'false'}
            {autocomplete}
            {placeholder}
            {inputmode}
            bind:this={inputElement}
            on:change={updateValue}
            on:change
            on:input
    />
</div>

<style>
    input {
        box-sizing: border-box;
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        font-size: 18px;
        display: block;
        height: 46px;
        width: 100%;
        position: relative;
        top:0;
        left: 0;
    }

    input:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }

    .animated-border {
        position: absolute;
        box-sizing: border-box;
        width: calc(100% + 4px);  /* + border of parent */
        height: calc(46px + 4px); /* + border of parent */
        background: transparent;
        left: -2px;
        top: -2px;
        pointer-events: none;
    }

    .animated-border:before,
    .animated-border:after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 3px solid transparent;
        width: 0;
        height: 0;
    }

    /* Top & right borders expands right, then down */
    .animated-border:before {
        top: 0;
        left: 0;
    }

    /* Bottom & left borders expands left, then up */
    .animated-border:after {
        bottom: 0;
        right: 0;
    }

    div:focus-within .animated-border:before,
    div:focus-within .animated-border:after {
        width: 100%;
        height: 100%;
    }

    div:focus-within .animated-border:before {
        border-top-color: var(--black);
        border-right-color: var(--black);
        transition:
                width 0.05s ease-out,
                height 0.05s ease-out 0.05s;
    }

    div:focus-within .animated-border:after {
        border-bottom-color: var(--black);
        border-left-color: var(--black);
        transition:
                width 0.05s ease-out 0.1s,
                height 0.05s ease-out 0.15s;
    }
</style>