<script>
    import FormInput from "./FormInput.svelte";
    import {afterUpdate} from "svelte";

    export let name;
    export let label;
    export let visuallyHidden;

    const initiallyHidden = visuallyHidden;

    let wrapper;

    function setTabbable(container, tabable) {
        const tabables = container.querySelectorAll(
            'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]'
        );
        tabables.forEach(e => e.setAttribute('tabindex', tabable ? '0' : '-1'));
    }

    afterUpdate(() => {
        if (visuallyHidden) {
            setTabbable(wrapper, false)
        } else if (initiallyHidden) {
            setTabbable(wrapper, true)
        }
    })
</script>

<div class="wrapper" class:hidden={visuallyHidden}>
    <FormInput {name}>
        <div bind:this={wrapper} class="input">
            <label for={name}>{label}</label>
            <slot />
        </div>
    </FormInput>
</div>

<style>
    .wrapper {
        position: relative;
        pointer-events: auto;
        opacity: 1;
        display: block;
        width: 100%;
        flex-grow: 1;
        margin-top: 15px;
    }

    div.hidden {
        overflow: hidden;
        position: fixed;
        pointer-events: none;
        top: 0;
        opacity: 0;
        max-height: 0;
    }

    .input {
        box-sizing: border-box;
        border: 2px solid var(--fieldGray);
        display: block;
        height: 50px;
        position:relative;
        width: 100%;
    }

    label {
        color: var(--black);
        position: absolute;
        top: -1.5em;
        left: 0;
        font-size: 16px;
        line-height: 1;
        font-weight: bold;
    }

    div :global(> .error) {
        margin-top: .5rem;
    }

</style>
