<script>
    import StyledInputField from "./StyledInputField.svelte";
    import LabelledFormInput from "./LabelledFormInput.svelte";
    import {tick} from "svelte";

    export let label = ''
    export let type = 'text'
    export let name;
    export let value = '';
    export let required = false;
    export let maxLength;
    export let autocomplete;
    export let placeholder;
    export let visuallyHidden = false;

    let input;

    export async function focus() {
        await tick();
        input.focus();
    }
</script>

<LabelledFormInput {name} {label} {visuallyHidden}>
    <StyledInputField {name} bind:this={input} bind:value={value} {type} {required} {maxLength} {autocomplete} {placeholder} {visuallyHidden} on:input on:change />
</LabelledFormInput>

<style>

</style>